.App {
  text-align: center;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 12vmax;
    pointer-events: none;
}

.App-roadmap {
    padding-top: 5vmax;
    height: 22vmax;
    pointer-events: none;
    padding-bottom: 10vmax;
}


.App-header {
    display: flex;
    padding-top: 4vh;
    padding-bottom: 4vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2vmax;
  color: white;
}

.line_parent {
    display: flex;
    margin-top: 2vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 22vh;
    color:white;
}

.App-link {
  color: #61dafb;
}

a {
    color: #FFA7E1;
    text-decoration: none;
}
.notice {
    font-size: 12pt;
    color: gray;
    margin-top: 2vh;
}

.line_first {
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 12vh;
    background-color: #FFA7E1;
}

.line_second {
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 6vh;
    background-color: #FFA7E1;;
}


.line_parent {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.scrolling_text {
    font-weight: bold;
    white-space: nowrap;
}
