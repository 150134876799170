.editor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 12pt;
    margin-left: 20vw;
    margin-right: 20vw;
    border: #232323 4px solid;
    max-width: 60%;
    max-height: 60vh;
    overflow: hidden;
}

.editor > * {
    max-width: 50%;
    width: 50%;
    height: 100%;
    border: none;
}

@media screen and (max-width: 600px) {
    .editor {
        max-height: 80vh;
        flex-direction: column;
    }
    .editor > :last-child {
        overflow: scroll;
    }
    .editor > * {
        background-color: red;
        max-width: 100%;
        width: 100%;
        height: 50%;
        border: none;
    }
}

